.shareapps-img {
	width: var(--layered-device-width);
	height: 250px;
	object-fit: cover;
	object-position: center;
}

.shareapps-ui-subfield.recipe-ingname {
	width: 380px;
}

.shareapps-ui-subfield.recipe-quantity {
	width: 100px;
}

.shareapps-ui-subfield.recipe-uom {
	width: 100px;
}

.recipe-ing-width {
	width: 90%;
}

/*.recipes-form {
	background-color: lightslategray;
	opacity: 0.8;
}*/

button.recipes-button {
	margin: 0px;
}

.recipes-action-panel {
	width: 500px;
	justify-content: space-between;
	display: flex;
}

@media only screen and (max-width: 768px) {
	/* size for mobile screens */
	.recipes-action-panel {
		width: 80%;
	}
}